html,
body {
  height: 100%;
  margin: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure the page container takes the full height */
}

.content-wrap {
  flex: 1;
  /* This will allow the content to grow and push the footer down */
}

.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-image {
  max-width: 10%;
  height: auto;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}

.table-content {
  border-collapse: collapse;
  /* Collapse borders into a single border */
  font-size: 0.7rem;
  /* Adjust the font size as needed */
}

.table-content th,
.table-content td {
  border: 1px solid #ddd;
  /* Add border to cells */
  padding: 8px;
  /* Add padding to cells for spacing */
}

.username-right {
  text-align: right;
}

.form-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.form-field label {
  width: 150px;
  /* Adjust the width as needed */
  margin-right: 10px;
}

.form-field input,
.form-field select,
.form-field textarea {
  flex: 0 1 auto;
}