.hamburger-menu-icon {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1000;
}

.toggle-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 20px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 14px;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 150px;
  height: 100%;
  background-color: #333;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.menu.open {
  transform: translateX(0);
}

.menu-items {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.menu-items a {
  color: white;
  text-decoration: none;
  margin: 10px 0;
}

.menu-icon {
  cursor: pointer;
}

.line {
  width: 30px;
  height: 3px;
  background-color: black;
  margin: 5px 0;
}

.page-container {
  padding: 20px;
}

body.menu-open {
  /* Adjust the page size or layout when the menu is open */
  margin-left: 150px; /* Example adjustment */
}

body {
  transition: margin-left 0.3s ease;
}